import numeral from 'numeral';
import is from 'is_js';

import CommonJson from 'src/json/common.json';

import Profile from './Profile';
import Garage from './Garage';


export default class Order {
  constructor(params) {
    const {
      type,
      id,
      ownerId,
      door,
      doorId,
      status,
      price,
      spacesPrice,
      deposit,
      ordererName,
      ordererInfo,
      paymentType,
      serialNumber,
      queueIndex,
      contact,
      refer,
      referrer,
      referrerId,
      style,
      remark,
      soldAt,
      updatedAt,
      createdAt,
      garagesSelected
    } = params;

    this.type = type;
    this.id = id;
    this.ownerId = ownerId;
    this.door = door;
    this.doorId = doorId;
    this.status = status;
    this.price = price;
    this.spacesPrice = spacesPrice;
    this.deposit = deposit;
    this.ordererName = ordererName;
    this.ordererInfo = ordererInfo;
    this.paymentType = paymentType;
    this.serialNumber = serialNumber;
    this.queueIndex = queueIndex;
    this.contact = contact;
    this.refer = refer;
    this.referrer = referrer ? Profile.fromRes(referrer) : null;
    this.referrerId = referrerId;
    this.style = style;
    this.remark = remark;
    this.soldAt = soldAt;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.garagesSelected = garagesSelected?.map((garage) => Garage.fromRes((garage))) ?? [];
  }

  get statusLabel() {
    switch (this.status) {
      case 'sold':
        return '成交';
      case 'notSold':
        return '無成交';
      case 'abandon':
        return '已退訂';
      case 'pending':
        return '已下訂';
      default:
        return this.status;
    }
  }

  get paymentTypeLabel() {
    switch (this.paymentType) {
      case 'direct':
        return '直接付款';
      case 'link':
        return '付款連結';
      default:
        return this.paymentType;
    }
  }

  get household() {
    try {
      return `${this.door.floor.building.name}/${this.door.floor.name}/${this.door.name}`;
    } catch (err) {
      return '';
    }
  }

  get priceLabel() {
    return is.existy(this.price) ? `${CommonJson.currency ?? 'NT$'} ${numeral(this.price).format()}` : '';
  }

  static fromRes(data) {
    return new Order(data);
  }
}
