import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image, View, StyleSheet } from '@react-pdf/renderer';

import dayjs from 'dayjs';
import numeral from 'numeral';
import is from 'is_js';

import logoIcon from 'src/assets/logo.png';
import CommonJson from 'src/json/common.json';

const Header = (props) => {
  const logo = CommonJson?.logo ?? logoIcon;

  const { order } = props;

  // 下訂時間
  const createdAt = dayjs(order?.createdAt).format('YYYY年MM月DD日HH:mm');

  const door = [
    order?.door?.floor?.building?.name,
    order?.door?.floor?.name,
    order?.door?.name
  ]
    .filter((t) => !!t)
    .join(', ');

  const space = (order?.garagesSelected ?? [])
    .map((g) => `${g.space.area.prefix} ${g.space.area.name} ${g.space.name}`)
    .join(', ');

  const referrer = (() => {
    if (!order) { return ''; }
    if (is.existy(order.refer)) {
      return order.refer;
    }
    return is.existy(order.referrer) ? `${order.referrer.lastName}${order.referrer.firstName}` : '網路自行選購';
  })();

  const formatTotalPrice = () => {
    const total = Number(order?.price) + Number(order?.spacesPrice);
    return `${numeral(total).format()}`;
  };

  return (
    <View style={styles.header}>
      <View style={styles.row}>
        <Image style={styles.logo} src={logo} />
        <View style={{ flex: 1 }} />
        <View style={styles.orderIdBox}>
          <Text style={styles.orderId}>{`訂單編號: ${order?.id ?? ''}`}</Text>
          <Text style={styles.createdAt}>{`下訂時間: ${createdAt ?? ''}`}</Text>
        </View>
      </View>

      <View style={styles.content}>
        <View style={styles.separator} />

        <View style={styles.title}>
          <Text style={styles.titleTxt}>{props.plan?.name}</Text>
        </View>

        <View style={styles.info}>
          <Text style={styles.doorTxt}>{`戶別：${door}`}</Text>
          <Text style={styles.spaceTxt}>{`車位：${space}`}</Text>
          <View style={styles.separator} />
          <Text style={styles.contactTxt}>
            {`承辦人：${referrer}`}
          </Text>
          <Text style={styles.priceTxt}>{`總金額：${CommonJson.currency ?? 'NT$'} ${formatTotalPrice()}`}</Text>
        </View>
      </View>
    </View>
  );
};

Header.propTypes = {
  order: PropTypes.object,
  plan: PropTypes.object
};

Header.defaultProps = {
  order: null,
  plan: null
};

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid red',
    margin: '20px 0px 0px 0px'
  },
  logo: {
    width: 110,
    height: 44
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    margin: '0px 20px 0px 20px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 40px 0px 40px'
  },
  separator: {
    height: 1,
    backgroundColor: '#C4C4C4',
    margin: '15px 0px'
  },
  orderIdBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    textAlign: 'right'
  },
  orderId: {
    fontFamily: 'custom',
    fontSize: 12,
    fontWeight: 400,
    color: '#2C2C2C'
  },
  createdAt: {
    fontFamily: 'custom',
    fontSize: 12,
    fontWeight: 400,
    color: '#2C2C2C',
    marginTop: 10
  },
  title: {
    padding: '0 20px 0 0'
  },
  titleTxt: {
    fontFamily: 'custom',
    fontSize: 43,
    fontWeight: 700,
    color: '#2C2C2C'
  },
  info: {
    display: 'flex',
    flexDirection: 'column'
  },
  doorTxt: {
    fontFamily: 'custom',
    fontSize: 12,
    fontWeight: 400,
    color: '#2C2C2C',
    marginTop: 12
  },
  spaceTxt: {
    fontFamily: 'custom',
    fontSize: 12,
    fontWeight: 400,
    color: '#2C2C2C',
    marginTop: 10
  },
  contactTxt: {
    fontFamily: 'custom',
    fontSize: 12,
    fontWeight: 400,
    color: '#2C2C2C'
  },
  priceTxt: {
    fontFamily: 'custom',
    fontSize: 12,
    fontWeight: 400,
    color: '#2C2C2C',
    marginTop: 10
  }
});

export default Header;
