import React from 'react';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';

import { AppStateContext } from 'src/components/AppStateProvider';
import UserService from 'src/services/user';

let updatedAt = null;

const withProfile = (WrappedComponent, isRequired = true) => {
  class WithProfileHOC extends React.Component {
    constructor() {
      super();

      this._modal = null;
    }

    async componentDidMount() {
      const { actions } = this.context ?? {};

      try {
        const now = dayjs().valueOf();
        if (updatedAt === null || dayjs(now).diff(dayjs(updatedAt)) > 3000) {
          updatedAt = now;
          const p = await UserService.getMyProfile();

          actions.updateProfile(p);
        }
        // NOTE: work around for isRequired page.
        if (isRequired && !this.context.state.profile) {
          this.props.history?.replace('/login', { target: this.props.location?.pathname });
        }
      } catch (err) {

        console.log('we are in error now');

        console.log('err', err);

        actions.updateProfile(null);
        UserService.logout();

        if (isRequired) {
          this.props.history?.replace('/login', { target: this.props.location?.pathname });
        }
      }
    }

    render() {
      const {
        forwardedRef,
        match, location, history,
        ...ps
      } = this.props;

      return isRequired && !this.context.state.profile ? null : (
        <WrappedComponent
          {...ps}
          profile={this.context.state.profile}
          ref={forwardedRef}
        />
      );
    }
  }

  WithProfileHOC.contextType = AppStateContext;

  const Component = withRouter(WithProfileHOC);

  return React.forwardRef((props, ref) => {
    return <Component {...props} forwardedRef={ref} />;
  });
};

export default withProfile;
