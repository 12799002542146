import dayjs from 'dayjs';
import is from 'is_js';
import numeral from 'numeral';

import planJSON from 'src/json/plan.json';
import CommonJSON from 'src/json/common.json';
import preOrderJson from 'src/json/preOrder.json';
import otherJson from 'src/json/other.json';
import { findIndexesByDoorId } from 'src/utils';

export const Status = {
  sold: 'sold',
  pending: 'pending',
  notSold: 'notSold',
  abandon: 'abandon',
  selecting: 'selecting',
  payment: 'payment'
};

export default class Model {
  constructor(params, buildings) {
    const {
      type,
      status,
      id,
      door,
      updatedAt,
      price,
      household,
      style,
      paymentType,
      garagesPreselections,
      spacesPrice
    } = params; // from Order model, PreOrder model

    this.type = type;
    this.status = status;
    this.id = id;
    this.door = door;
    this.updatedAt = updatedAt;
    this.price = price;
    this.household = household;
    this.style = style;
    this.paymentType = paymentType;
    this.garagesPreselections = garagesPreselections;
    this.spacesPrice = spacesPrice ?? 0;

    this.buildings = buildings;
    // 到 payment 需要 pid

    // 到 order 需要 oid

    // 到 preOrder 需要 pid
  }

  get orderType() {
    if (this.type === 'order') {
      return this.status;
    }

    if (this.type === 'preorder') {
      if (is.null(this.style) && is.null(this.paymentType)) return Status.selecting;
      return Status.selecting;
    }

    return null;
  }

  get tag() {
    switch (this.orderType) {
      case Status.sold:
        return '賀成交';
      case Status.notSold:
        return '已售出';
      case Status.abandon:
        return '已退訂';
      case Status.pending:
        return '已下訂';
      case Status.selecting:
        return '選戶中';
      // case Status.payment:
      //   return '付款中';
      default:
        return null;
    }
  }

  get updateTime() {
    const gap = dayjs().diff(this.updatedAt, 'day');
    const duration = dayjs().diff(this.updatedAt);
    if (gap < 1) {
      const hours = dayjs.duration(duration).hours();
      const mins = dayjs.duration(duration).minutes();
      if (hours === 0 && mins === 0) return dayjs(this.updatedAt).format('HH:mm');
      return `${hours > 0 ? `${hours}h` : ''}${mins > 0 ? `${mins}m` : ''}`;
    }
    return dayjs(this.updatedAt).format('MM/DD/YYYY');
  }

  get img() {
    return otherJson.order.finish;
  }

  get remarks() {
    const doorID = this.door.id;
    const [bIdx, fIdx, dIdx] = findIndexesByDoorId(this.buildings, doorID);
    const doorInfo = ((planJSON.buildings[bIdx]).floors[fIdx]).doors[dIdx];
    const remark1 = `${doorInfo.remark1}${doorInfo.remarkcontent1}${doorInfo.remarkunit1}`;
    const remark2 = `${doorInfo.remark2}${doorInfo.remarkcontent2}${doorInfo.remarkunit2}`;
    const remark3 = `${doorInfo.remark3}${doorInfo.remarkcontent3}${doorInfo.remarkunit3}`;
    return [remark1, remark2, remark3];
  }

  get householdPrice() {
    if (this.type === 'preorder') {
      return this.door?.price ?? 0;
    }
    return this.price ?? 0;
  }

  get formatHouseholdPrice() {
    return `${CommonJSON.currency ?? 'NT$'} ${numeral(this.householdPrice).format()}`;
  }


  get getSpacesPrice() {
    if (this.type === 'preorder') {
      let sum = 0;
      this.garagesPreselections?.forEach((garage) => {
        sum += Number(garage.space.price);
      });
      return sum ?? 0;
    }
    return this.spacesPrice ?? 0;
  }

  get formatSpacesPrice() {
    return `${CommonJSON.currency ?? 'NT$'} ${numeral(this.getSpacesPrice).format()}`;
  }


  get formatTotalPrice() {
    const total = Number(this.householdPrice) + Number(this.getSpacesPrice);
    return `${CommonJSON.currency ?? 'NT$'} ${numeral(total).format()}`;
  }

  get garagesUrl() {
    // car slots query string
    const slots = this.garagesPreselections.map((garage) => {
      if (garage.slot) {
        return `slot=${encodeURIComponent(garage.slot.id)}`;
      }
      return 'slot=null';
    });
    const url = slots.join('&');
    return `&${url}`;
  }


  get carSpaceUrl() {
    // car slots query string
    const spaces = this.garagesPreselections.map((garage) => {
      if (garage.space) {
        return `space=${encodeURIComponent(garage.space.id)}`;
      }
      return 'space=null';
    });
    const url = spaces.join('&');
    return `&${url}`;
  }

  get designUrl() {
    if (this.style) {
      const index = preOrderJson.styles.findIndex(
        (d) => d.style1 === this.style
      );
      return `&design=${index}`;
    }
    return '&design=null';
  }



  static fromRes(data, buildings) {
    return new Model(data, buildings);
  }
}
