import numeral from 'numeral';

export const getYouTubeId = (url) => {
  const regExp
    = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?\??v?=?))([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

export const delay = (ms) => new Promise((r) => setTimeout(r, ms));

/**
 *
 * @param {object[]} buildings
 * @param {number} doorId
 * @returns {number[]} [buildingIdx, floorIdx, doorIdx]
 */
export const findIndexesByDoorId = (buildings, doorId) => {
  try {
    buildings.forEach((building, bIdx) => {
      building.floors.forEach((floor, fIdx) => {
        floor.doors.forEach((door, dIdx) => {
          if (door.id === doorId) {
            // eslint-disable-next-line no-throw-literal
            throw [bIdx, fIdx, dIdx];
          }
        });
      });
    });
  } catch (found) {
    return found;
  }
  return [];
};

/**
 *
 * @param {object[]} buildings
 * @param {object} door { name, floor: { name, building: { name } } }
 * @returns {object} door
 */
export const findDoorByNames = (buildings, door) => {
  try {
    buildings.forEach((b) => {
      if (b.name === door.floor.building.name) {
        b.floors.forEach((f) => {
          if (f.name === door.floor.name) {
            f.doors.forEach((d) => {
              if (d.name === door.name) {
                // eslint-disable-next-line no-throw-literal
                throw d;
              }
            });
          }
        });
      }
    });
  } catch (found) {
    return found;
  }
  return null;
};

/**
 *
 * @param {object[]} buildings
 * @param {object} door { name, floor: { name, building: { name } } }
 * @returns {object} door
 */
// 還會再改
export const findBuildingByNames = (buildings, door) => {
  try {
    buildings.forEach((b) => {
      if (b.name === door.floor.building.name) {
        throw b;
      }
    });
  } catch (found) {
    return found;
  }
  return null;
};

export const getMonthlyPayment = (total, rate) => {
  // 試算公式：
  // 每月應付本息金額之平均攤還率 ＝{[(1＋月利率)^月數]×月利率}÷{[(1＋月利率)^月數]－1}
  // (公式中：月利率 ＝ 年利率／12 ； 月數=貸款年期 ｘ 12)
  // 每月應攤還本金與利息試算：
  // 平均每月應攤付本息金額＝貸款本金×每月應付本息金額之平均攤還率 ＝每月應還本金金額＋每月應付利息金額

  const monthlyInterestRate = numeral(rate).divide(100).divide(12).value();
  const p = (1 + monthlyInterestRate) ** 360;
  const averageRepayRate = (numeral(p || 0).multiply(monthlyInterestRate)).divide((p - 1)).value();
  return numeral(total || 0).multiply(averageRepayRate).value();
};


/**
 *
 * @param {string} value
 * @param {(string|RegExp)[]} patterns
 * @returns {boolean}
 */
export const some = (value, patterns = []) => {
  return patterns.some((p) => {
    const reg = Object.prototype.toString.call(value) === '[object String]' ? new RegExp(p) : p;
    return reg.test(value);
  });
};

export const group = (arr, size) => Array.from(
  { length: Math.ceil(arr.length / size) },
  (_, idx) => arr.slice(idx * size, (idx + 1) * size)
);


/**
 *
 * @param {string} text
 * @param {string} font // 格式 'bold 32px "Noto Sans TC"'
 * @returns {number}
 */

export const getTextWidth = (text, font = '16px "Noto Sans TC"') => {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

export const preventZoom = () => {
  // > see https://medium.com/@littleDog/%E5%A6%82%E4%BD%95%E8%A7%A3%E6%B1%BA-user-scalable-no-%E5%B1%AC%E6%80%A7%E8%A2%ABios-safari-ignore-e6a0531050ba

  document.addEventListener('touchstart', (event) => {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }, { passive: false });

  let lastTouchEnd = 0;
  document.addEventListener('touchend', (event) => {
    const now = (new Date()).getTime();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);
};
