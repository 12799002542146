import { observable, makeObservable, action, computed } from 'mobx';

import { avatars } from 'src/constants';
import defaultMemberImg from 'src/assets/memberIcon.png';
import logoIcon from 'src/assets/logo.png';
import commonJson from 'src/json/common.json';

export default class NavBarViewModel {
  @observable isShow = true;
  @observable profile = null;
  @observable userIdentity = 'sales';
  @observable adSlideIndex;
  @observable extraLink = null;

  constructor() {
    makeObservable(this);
  }

  get logoImg() {
    return commonJson.logo || logoIcon;
  }

  @action
  changeShowStatus = (status) => {
    if (status === 'show') {
      this.isShow = true;
    } else if (status === 'hide') {
      this.isShow = false;
    } else {
      this.isShow = !this.isShow;
    }
  }

  @action
  changeActiveAdSlide = (index) => {
    this.adSlideIndex = index;
  }

  @action
  handleChangeSection = (status, index) => {
    this.changeShowStatus(status);
    this.changeActiveAdSlide(index);
  }

  @action
  updateProfile = (profile) => {
    this.profile = profile;
  }

  @action
  updateExtraLink = (extraLink) => {
    this.extraLink = extraLink;
  }

  @computed get memberImg() {
    if (!this.profile) {
      return commonJson.signup_icon || defaultMemberImg;
    }
    return avatars[this.profile.avatar];
  }
}
