import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import cSModule from 'src/services/csModule';
import salesPopup from 'src/services/salesPopup';

import withProfile from 'src/components/withProfile';
import backIcon from 'src/assets/leftArrow.png';

import styles from './styles.module.css';
import guestProfile from '../../assets/guestProfile.svg';
import consumerProfile from '../../assets/consumerProfile.svg';

@observer
class NavBar extends React.Component {
  componentDidUpdate() {
    const { profile } = this.props;

    this.props.viewModel.updateProfile(profile);
    if (profile && salesPopup.salesList.length === 0) {
      salesPopup.didUpdate();
    }
  }

  render() {
    const { unread, show } = cSModule;
    const { viewModel } = this.props;
    const { isShow, profile } = viewModel;
    const profileImg
      = viewModel.profile?.group === 'sales'
        || viewModel.profile?.group === 'consumer'
        ? consumerProfile
        : guestProfile;
    const isJustPickOut = this.props.location.pathname === '/pickout';
    const isPickPutPage
      = this.props.location.pathname === '/pickout'
      || this.props.location.pathname === '/preorder';

    const showBackBtn
      = this.props.location.pathname === '/preorder'
      || this.props.location.pathname === '/login';

    return (
      <>
        {/* web */}
        <MediaQuery minWidth={769}>
          <div className={styles.navBar}>
            <div className={styles.contentContainer}>
              {showBackBtn && (
                <div
                  className={styles.back_btn}
                  onClick={() => this.props.history.goBack()}
                >
                  <img src={backIcon} width={8} height={15} alt="" />
                </div>
              )}

              <Link to="/">
                <img
                  src={viewModel.logoImg}
                  alt="logo"
                  className={styles.logoImg}
                />
              </Link>
            </div>

            <div className={styles.contentContainer}>
              {!isPickPutPage && (
                <Link to="/pickout" className={styles.toPickOutContainer}>
                  <div className={styles.pickOutText}>查看價格</div>
                </Link>
              )}
              {
                isJustPickOut && viewModel.extraLink
                && (
                  <a href={viewModel.extraLink} target="_blank" rel="noreferrer" className={styles.toPickOutContainer}>
                    <div className={styles.pickOutText}>預約看房</div>
                  </a>
                )
              }
              <Link to="/center" className={styles.toCenterContainer}>
                <img
                  src={profileImg}
                  alt="member"
                  className={styles.memberImg}
                />
                <div className={styles.name}>
                  {this.props.profile?.lastName
                    + this.props.profile?.firstName || ''}
                </div>
              </Link>
            </div>
          </div>
        </MediaQuery>
        {/* mobile */}
        <MediaQuery maxWidth={768}>
          <div className={clsx(styles.navBar)}>
            <div className={styles.contentContainer}>
              {showBackBtn && (
                <div
                  className={styles.back_btn}
                  onClick={() => this.props.history.goBack()}
                >
                  <img src={backIcon} width={8} height={15} alt="" />
                </div>
              )}

              <Link to="/">
                <img
                  src={viewModel.logoImg}
                  alt="logo"
                  className={styles.logoImg}
                />
              </Link>
            </div>

            <div className={styles.contentContainer}>
              {!isPickPutPage && (
                <Link to="/pickout" className={styles.toPickOutContainer}>
                  <div className={styles.pickOutText}>查看價格</div>
                </Link>
              )}
              {
                isJustPickOut && viewModel.extraLink
                && (
                  <a href={viewModel.extraLink} target="_blank" rel="noreferrer" className={styles.toPickOutContainer}>
                    <div className={styles.pickOutText}>預約看房</div>
                  </a>
                )
              }
              <Link to="/center">
                <img
                  src={profileImg}
                  alt="member"
                  className={styles.memberImg}
                />
              </Link>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

NavBar.propTypes = {
  history: PropTypes.object,
  viewModel: PropTypes.object,
  profile: PropTypes.object,
  location: PropTypes.object
};

NavBar.defaultProps = {
  history: null,
  viewModel: null,
  profile: null,
  location: null
};

export default withProfile(withRouter(NavBar), false);
